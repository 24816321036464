import React from 'react';
import Image from 'next/image';
import benefits from '../../styles/Benefits.module.scss';

interface IItemDetail {
	imgSrc: string;
	title: string;
	content: string;
}

export default function ItemDetail({ imgSrc, title, content }: IItemDetail) {
	return (
		<div className={benefits.itemDetail}>
			<Image
				src={imgSrc}
				alt='alt.real logo desktop'
				className={`${benefits.benefitIcon} position-relative`}
				fill
			/>
			<h2 className={benefits.title}>{title}</h2>
			<p className={benefits.content}>{content}</p>
		</div>
	);
}
