import React, { useEffect } from 'react';
import useEmblaCarousel from 'embla-carousel-react';
import Autoplay from 'embla-carousel-autoplay';
import emblas from '../../styles/Embla.module.scss';
import Image from 'next/image';
import {
	ICarouselImg,
	ICarouselOptions,
} from '../../data/abstractions/landingInterfaces';

interface ICarouselConfiguration {
	slides: ICarouselImg[];
	options: ICarouselOptions;
	selectedSlide: number;
	priority?: boolean;
	customCss?: string;
	banner?: boolean;
}

export default function Carousel({
	slides,
	options,
	selectedSlide,
	priority = false,
	customCss,
	banner = false,
}: ICarouselConfiguration) {
	const autoplayOptions = {
		delay: 4000,
		rootNode: (emblaRoot: { parentElement: any }) => emblaRoot.parentElement,
	};

	// const [emblaRef, emblaApi] = useEmblaCarousel(options, [
	// 	Autoplay(autoplayOptions),
	// ]);
	const [emblaRef, emblaApi] = useEmblaCarousel(options);

	useEffect(() => {
		if (!emblaApi) return;
		emblaApi.scrollTo(selectedSlide);
	}, [selectedSlide]);
	return (
		<div className={`${emblas.embla} ${banner ? emblas.bannerSize : ''}`}>
			<div className={emblas.embla__viewport} ref={emblaRef}>
				<div className={emblas.embla__container}>
					{slides.map((slide, index) => (
						<div className={emblas.embla__slide} key={index}>
							<div className={emblas.embla__slide__number}>
								<span>{index + 1}</span>
							</div>
							<Image
								src={slide.path}
								alt={slide.alt}
								className={emblas.embla__slide__img}
								fill
								loading={index === 0 ? 'eager' : 'lazy'}
								priority={index === 0 ? true : priority}
								placeholder='blur'
								blurDataURL='/images/loading-image-placeholder.svg'
							/>
						</div>
					))}
				</div>
			</div>
		</div>
	);
}
