import React, { useState } from 'react';
import Image from 'next/image';
import testimonials from '/styles/Testimonials.module.scss';
import TestimonialCarousel from 'components/ui/testimonialsCarousel';
import { CONFIG } from 'data/models/types/testimonialsConfig';

export default function Testimonials() {
	const [selectedHeroIndex, setHeroIndex] = useState(1);
	return (
		<section className={`${testimonials.testimonials}`}>
			<div
				className={`${testimonials.testimonialsGrid} ${
					false ? 'container' : ''
				}`}
			>
				<Image
					src='/images/landing/solar-panel-group-diagram.svg'
					alt='alt.real solar panels installer'
					className={testimonials.testimonialsImg}
					fill
				/>
				<div className={testimonials.testimonialsContainer}>
					<TestimonialCarousel
						slides={CONFIG.DATA}
						options={CONFIG.OPTIONS}
						selectedSlide={selectedHeroIndex}
					></TestimonialCarousel>
				</div>
			</div>
		</section>
	);
}
