import { ITestimonial } from '../../abstractions/configuratorInterfaces';

export const MOCK_TESTIMONIALS: ITestimonial[] = [
	// {
	// 	name: 'CTO alt.real',
	// 	text: 'Prin alt.real, ne propunem să aducem încredere și stabilitate în piața energiei regenerabile.',
	// 	imgSrc:
	// 		'/images/landing/testimonials/a318718f-0b26-46f1-9fd9-1b1fae2cdb4b.jpg',
	// },
	{
		name: 'Lucretiu - Ilfov',
		text: 'Mi-au salvat foarte mult timp cu alegerea unui sistem potrivit, am instalat 10 kW la mine acasa. Totul a decurs rapid si eficient.',
		imgSrc:
			'/images/landing/testimonials/9119d346-d8f7-40f2-85a8-716291f6ad91.jpg',
		rating: 5,
	},
	{
		name: 'Viorel - Ateea Pam SRL',
		text: 'Recomandăm cu încredere alt.real pentru sisteme fotovoltaice. Am avut o experiență foarte mulțumitoare, ne-au oferit asistenta pentru alegerea si instalarea unui sistem potrivit nevoilor noastre la un pret rezonabil.',
		imgSrc:
			'/images/landing/testimonials/3fb815f0-9608-4788-8170-076496280d8c.jpg',
		rating: 5,
	},
	{
		name: 'Emanuel - Tulcea',
		text: 'Recomand, profesionalism si seriozitate!',
		imgSrc:
			'/images/landing/testimonials/dd03bc01-c0e3-4886-a360-560f49a66591.png',
		rating: 5,
	},
	{
		name: 'Theodor - Teleorman',
		text: 'Recomand! Am colaborat cu ei si sunt foarte multumit!',
		imgSrc:
			'/images/landing/testimonials/bc449567-779e-4f4b-966b-2cebaa781f4b.png',
		rating: 5,
	},
	// {
	// 	//name: 'Client alt.real',
	// 	text: 'Am fost plăcut impresionat de comunicarea cu consultanții alt.real. Odată acceptată oferta partenerul a fost prompt și s-a comportat ca un adevărat profesionist.',
	// 	rating: 5,
	// },
	// {
	// 	name: 'CEO alt.real',
	// 	text: 'Scopul nostru este să oferim cea mai bună experiență pentru persoanele și firmele care își doresc să beneficieze de energie solară.',
	// 	imgSrc:
	// 		'/images/landing/testimonials/db0ce2d3-8522-40f2-b558-87aa7a5030cc.jpg',
	// },
	{
		//name: 'Camelia - GreenSun 2005',
		text: 'Am primit oferte rapid si am fost ajutata sa o aleg pe cea mai potrivita pentru mine. Montajul a decurs fara probleme.',
		rating: 5,
	},
];
