import React, {
	useCallback,
	useEffect,
	useLayoutEffect,
	useState,
} from 'react';
import useEmblaCarousel from 'embla-carousel-react';
import Autoplay from 'embla-carousel-autoplay';
import { FormattedMessage, useIntl } from 'react-intl';
import featuredIn from 'styles/FeaturedIn.module.scss';
import Image from 'next/image';

import {
	CAROUSEL_IMGS,
	CAROUSEL_OPTIONS,
} from 'helpers/featuredInSlidesConfigurations';

type CarouselImage = {
	href?: string;
	path: string;
	alt: string;
};

const MEDIA_PRESENCE_LOGOS: CarouselImage[] = CAROUSEL_IMGS;

type ImageGroup = CarouselImage[][];

interface ICarouselControlButton {
	enabled: boolean;
	onClick: () => void;
}

export function PrevButton({ enabled, onClick }: ICarouselControlButton) {
	const intl = useIntl();
	return (
		<button
			className={`${featuredIn.embla__button} ${featuredIn.embla__button__prev}`}
			onClick={onClick}
			disabled={!enabled}
			aria-label={intl.formatMessage({
				id: 'component.controls.prev',
				defaultMessage: 'Previous',
			})}
		>
			<svg
				className={featuredIn.embla__button__svg}
				viewBox='137.718 -1.001 366.563 644'
			>
				<path d='M428.36 12.5c16.67-16.67 43.76-16.67 60.42 0 16.67 16.67 16.67 43.76 0 60.42L241.7 320c148.25 148.24 230.61 230.6 247.08 247.08 16.67 16.66 16.67 43.75 0 60.42-16.67 16.66-43.76 16.67-60.42 0-27.72-27.71-249.45-249.37-277.16-277.08a42.308 42.308 0 0 1-12.48-30.34c0-11.1 4.1-22.05 12.48-30.42C206.63 234.23 400.64 40.21 428.36 12.5z' />
			</svg>
		</button>
	);
}

export function NextButton({ enabled, onClick }: ICarouselControlButton) {
	const intl = useIntl();
	return (
		<button
			className={`${featuredIn.embla__button} ${featuredIn.embla__button__next}`}
			onClick={onClick}
			disabled={!enabled}
			aria-label={intl.formatMessage({
				id: 'component.controls.next',
				defaultMessage: 'Next',
			})}
		>
			<svg
				className={featuredIn.embla__button__svg}
				viewBox='0 0 238.003 238.003'
			>
				<path d='M181.776 107.719L78.705 4.648c-6.198-6.198-16.273-6.198-22.47 0s-6.198 16.273 0 22.47l91.883 91.883-91.883 91.883c-6.198 6.198-6.198 16.273 0 22.47s16.273 6.198 22.47 0l103.071-103.039a15.741 15.741 0 0 0 4.64-11.283c0-4.13-1.526-8.199-4.64-11.313z' />
			</svg>
		</button>
	);
}

export default function FeaturedIn() {
	const [emblaRefFeaturedIn, emblaApiFeaturedIn] = useEmblaCarousel(
		CAROUSEL_OPTIONS,
		[]
	);

	const scrollPrev = useCallback(
		() => emblaApiFeaturedIn && emblaApiFeaturedIn.scrollPrev(),
		[emblaApiFeaturedIn]
	);
	const scrollNext = useCallback(
		() => emblaApiFeaturedIn && emblaApiFeaturedIn.scrollNext(),
		[emblaApiFeaturedIn]
	);

	const [groupedImages, setGroupedImages] = useState<ImageGroup>([]);
	const [groupSize, setGroupSize] = useState<number>(3);

	useEffect(() => {
		if (typeof window !== 'undefined') {
			const handleResize = () => {
				if (window.innerWidth >= 992) setGroupSize(3);
				else if (window.innerWidth < 992 && window.innerWidth >= 768)
					setGroupSize(2);
				else if (window.innerWidth < 768 && window.innerWidth >= 576)
					setGroupSize(1);
				else setGroupSize(1);
			};

			window.addEventListener('resize', handleResize);

			// Call handleResize once on component mount to set initial value
			handleResize();

			// Clean up event listener on component unmount
			return () => {
				window.removeEventListener('resize', handleResize);
			};
		}
	}, []);

	useLayoutEffect(() => {
		//useEffect(() => {
		const groups: ImageGroup = MEDIA_PRESENCE_LOGOS.reduce(
			(groupArray: ImageGroup, item, index) => {
				const groupIndex = Math.floor(index / groupSize);

				if (!groupArray[groupIndex]) {
					groupArray[groupIndex] = [];
				}

				groupArray[groupIndex].push(item);

				return groupArray;
			},
			[]
		);

		setGroupedImages(groups);
	}, [MEDIA_PRESENCE_LOGOS, groupSize]);

	return (
		<section className={`${featuredIn.featuredIn}`}>
			<div className={`container mt-4 mb-4`}>
				<h2
					className={`col-12 d-flex align-items-center justify-content-center pt-4 pb-4 h1`}
				>
					<FormattedMessage
						id='component.featured_in.title'
						defaultMessage='Apariții media'
					/>
				</h2>
			</div>
			<div className={featuredIn.testimonialsContainer}>
				<div className={featuredIn.embla}>
					<div className={featuredIn.embla__viewport} ref={emblaRefFeaturedIn}>
						<div className={featuredIn.embla__container}>
							{groupedImages.map((group, groupIndex) => (
								<div className={featuredIn.embla__slide} key={groupIndex}>
									{group.map((logo, index) => (
										<div className={featuredIn.embla__slide__group} key={index}>
											{logo.href && (
												<a target='_blank' rel='noreferrer' href={logo.href}>
													<Image
														src={logo.path}
														alt={logo.alt}
														width={200}
														height={150}
														loading='eager'
														priority={true}
													/>
												</a>
											)}
											{!logo.href && (
												<Image
													src={logo.path}
													alt={logo.alt}
													width={200}
													height={150}
													loading='eager'
													priority={true}
												/>
											)}
										</div>
									))}
								</div>
							))}
						</div>
						<div className={featuredIn.carouselControlsContainer}>
							<PrevButton onClick={scrollPrev} enabled={true} />
							<NextButton onClick={scrollNext} enabled={true} />
						</div>
					</div>
				</div>
			</div>
			{/* <div className={`${featuredIn.featuredInGrid} container`}> */}
			{/* {CAROUSEL_IMGS.map((slide, index) => {
					return (
						<a target='_blank' rel='noreferrer' href={slide.href} key={index}>
							<Image
								src={slide.path}
								alt={slide.alt}
								width={100}
								height={100}
								loading={'eager'}
								priority={true}
							/>
						</a>
					);
				})} */}
			{/* </div> */}
		</section>
	);
}
