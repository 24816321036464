import React, { useEffect } from "react";
import Image from "next/image";
import components from "styles/components.module.scss";
// @ts-ignore
import ReactStarsRating from "react-awesome-stars-rating";

interface IItemTile {
	id: number;
	title?: string;
	imgSrc: string;
	imgAlt: string;
	content: string;
	rating?: number;
}

export default function ItemTile({
	id,
	title,
	imgSrc,
	imgAlt,
	content,
	rating,
}: IItemTile) {
	useEffect(() => {
		const elem = document.querySelectorAll(
			'[data-testid="react-awesome-stars-rating"'
		);
		elem.forEach((item) => {
			item.setAttribute("aria-label", "review");
		});
	}, []);

	return (
		<div className={`${components.itemTile} itemTile`}>
			<div className={components.itemTileInner}>
				<Image
					src={imgSrc}
					alt={imgAlt}
					className={components.itemTileIcon}
					fill
				/>
				{rating && (
					<div className={components.rating}>
						<ReactStarsRating
							value={rating}
							isHalf={true}
							id={id.toString()}
							primaryColor={"#ffff00"}
							secondaryColor={"#313131"}
							starGap={5}
						></ReactStarsRating>
					</div>
				)}
				<div className={components.content}>&quot;{content}&quot;</div>
				{title && <div className={components.title}>{title}</div>}
			</div>
			<span className={components.photonDot}></span>
		</div>
	);
}
