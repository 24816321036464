import React from 'react';
import { useIntl } from 'react-intl';
import benefits from 'styles/Benefits.module.scss';
import ItemDetail from 'components/ui/itemDetail';

interface IBenefits {
	customCss?: string;
}

export default function Benefits(props: IBenefits) {
	const intl = useIntl();
	return (
		<section className={`${benefits.benefits} ${props.customCss}`}>
			<div className={`${benefits.benefitsGrid} container`}>
				{[...Array(6).keys()].map((id) => {
					return (
						<ItemDetail
							key={id}
							imgSrc={`/icons/benefits/benefits-icon-${id + 1}.webp`}
							title={intl.formatMessage({
								id: `component.benefits.${id + 1}.heading`,
							})}
							content={intl.formatMessage({
								id: `component.benefits.${id + 1}.label`,
							})}
						></ItemDetail>
					);
				})}
			</div>
		</section>
	);
}
