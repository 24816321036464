import React, { useRef } from 'react';
import { Helmet } from 'react-helmet';
import { FormattedMessage, useIntl } from 'react-intl';
import socialProofs from 'styles/SocialProof.module.scss';
import LiteYouTubeEmbed from 'react-lite-youtube-embed';
import 'react-lite-youtube-embed/dist/LiteYouTubeEmbed.css';

interface ISocialProof {
	customCss?: string;
}

export default function SocialProof(props: ISocialProof) {
	const socialProofIFrame = useRef(null);
	return (
		<section
			className={`${socialProofs.socialProof} ${props.customCss}`}
			id='social-proof'
		>
			<div
				className={`align-items-center d-flex h-100 justify-content-center position-relative flex-column`}
			>
				<div
					className={`${socialProofs.socialProofContainer} pb-3 pb-lg-5 pt-3 pt-lg-5`}
				>
					<div className='container text-center'>
						<div className={`${socialProofs.socialProofVideoContainer}`}>
							{/* <iframe
								width='1280'
								height='720'
								src='https://www.youtube.com/embed/yudAgBTsZCI'
								title='Rurality Show - Episodul 1: Panouri Fotovoltaice'
								className={socialProofs.socialProofVideo}
								allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
								allowFullScreen={true}
							></iframe> */}
							<LiteYouTubeEmbed
								id='yudAgBTsZCI' // Default none, id of the video or playlist
								adNetwork={true} // Default true, to preconnect or not to doubleclick addresses called by YouTube iframe (the adnetwork from Google)
								params='width=1280&height=720' // any params you want to pass to the URL, assume we already had '&' and pass your parameters string
								playlist={false} // Use true when your ID be from a playlist
								playlistCoverId='yudAgBTsZCI' // The ids for playlists did not bring the cover in a pattern to render so you'll need pick up a video from the playlist (or in fact, whatever id) and use to render the cover. There's a programmatic way to get the cover from YouTube API v3 but the aim of this component is do not make any another call and reduce requests and bandwidth usage as much as possibe
								poster='hqdefault' // Defines the image size to call on first render as poster image. Possible values are "default","mqdefault",  "hqdefault", "sddefault" and "maxresdefault". Default value for this prop is "hqdefault". Please be aware that "sddefault" and "maxresdefault", high resolution images are not always avaialble for every video. See: https://stackoverflow.com/questions/2068344/how-do-i-get-a-youtube-video-thumbnail-from-the-youtube-api
								title='Rurality Show - Episodul 1: Panouri Fotovoltaice' // a11y, always provide a title for iFrames: https://dequeuniversity.com/tips/provide-iframe-titles Help the web be accessible ;)
								noCookie={false} // Default false, connect to YouTube via the Privacy-Enhanced Mode using https://www.youtube-nocookie.com
								wrapperClass={`${socialProofs.socialProofVideo} yt-lite`}
								ref={socialProofIFrame} // Use this ref prop to programmatically access the underlying iframe element
							/>
							<Helmet>
								<meta property='og:type' content='video.movie' />
								<meta property='video:actor' content='Dragoș Bucur' />
								<meta property='video:actor:role' content='Lead Actor' />
								<meta property='video:duration' content='1178' />
								<meta property='video:release_date' content='2023-08-31' />
								<meta
									property='video:tag'
									content='Dragoș Bucur, Panouri Fotovoltaice, Rurality Show, Episodul 1, Alt.Real, Influențăran, ProTV, Visuri la cheie'
								/>
							</Helmet>
						</div>
						{/* <h2 className='mt-4'>
							<FormattedMessage id='component.about.mission.title'></FormattedMessage>
						</h2> */}
						<h3 className={socialProofs.citation}>
							{'"'}
							<FormattedMessage id='component.social_proof.testimonial_1.part_1'></FormattedMessage>
							{'"'}
						</h3>
						<h3 className={socialProofs.citation}>
							{/* <br className='mt-3'></br> */}
							{'"'}
							<FormattedMessage id='component.social_proof.testimonial_1.part_2'></FormattedMessage>
							{'"'}
						</h3>
						<h4 className='mt-3 fst-italic'>Dragoș Bucur</h4>
					</div>
				</div>
			</div>
		</section>
	);
}
