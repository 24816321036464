import React, { useEffect, useCallback } from 'react';
import useEmblaCarousel from 'embla-carousel-react';
import Autoplay from 'embla-carousel-autoplay';
import testimonials from 'styles/Testimonials.module.scss';
import ItemTile from './itemTile';
import { useIntl } from 'react-intl';
import { PrevButton, NextButton } from './carouselControls';

interface ITestimonialSlide {
	imgSrc?: string | undefined;
	alt?: string | undefined;
	name?: string | undefined;
	text?: string | undefined;
	rating?: number | undefined;
}

interface ITestimonialCarousel {
	slides: ITestimonialSlide[];
	options: object;
	selectedSlide: number;
}

export default function TestimonialCarousel({
	slides,
	options,
	selectedSlide,
}: ITestimonialCarousel) {
	const intl = useIntl();
	const [emblaRef, emblaApi] = useEmblaCarousel(options, [Autoplay()]);
	//const [emblaRef, emblaApi] = useEmblaCarousel(options);

	const scrollPrev = useCallback(
		() => emblaApi && emblaApi.scrollPrev(),
		[emblaApi]
	);
	const scrollNext = useCallback(
		() => emblaApi && emblaApi.scrollNext(),
		[emblaApi]
	);

	useEffect(() => {
		if (!emblaApi) return;
		emblaApi.scrollTo(selectedSlide);
	}, [emblaApi, selectedSlide]);
	return (
		<div className={testimonials.embla}>
			<div className={testimonials.embla__viewport} ref={emblaRef}>
				<div className={testimonials.embla__container}>
					{slides.map((slide, index) => (
						<div className={testimonials.embla__slide} key={index}>
							<div className={testimonials.embla__slide__number}>
								<span>{index + 1}</span>
							</div>
							<ItemTile
								key={index}
								id={index}
								imgSrc={slides[index].imgSrc ?? `/icons/user-icon.png`}
								title={
									slides[index].name ??
									intl.formatMessage({
										id: 'component.estimator.testimonials.anonymous_user',
										defaultMessage: 'Anonymous User',
									})
								}
								imgAlt={slides[index].alt ?? 'anonymous review user image'}
								content={slides[index].text ?? 'Unverified user'}
								rating={slides[index].rating}
							></ItemTile>
						</div>
					))}
				</div>
				<div className={testimonials.carouselControlsContainer}>
					<PrevButton onClick={scrollPrev} enabled={true}></PrevButton>
					<NextButton onClick={scrollNext} enabled={true}></NextButton>
				</div>
			</div>
		</div>
	);
}
